import { Translation } from "./model";

export type LangType = 'ja' | 'en' | 'es';
export const _Languages: LangType[] = ['ja', 'en', 'es'];

export const ComponentLabel = {
  Email: 'Email',
  ConditionRepository: 'ConditionRepository',
  HirouRepository: 'HirouRepository',
  session: 'session',
  alertview: 'alert-view',
  conditionicon: 'condition-icon',
  defaultheader: 'default-header',
  alertlist: 'alert-list',
  alertsummary: 'alert-summary',
  chatlist: 'chat-list',
  conditiondetail: 'condition-detail',
  conditionlist: 'condition-list',
  devicelist: 'device-list',
  hiroulist: 'hirou-list',
  itemdetailchart: 'item-detail-chart',
  login: 'login',
  managerlist: 'manager-list',
  map: 'map',
  nettyushorisk: 'nettyusho-risk',
  nettyushoriskchart: 'nettyusho-risk-chart',
  pagenotfound: 'page-not-found',
  projectlist: 'project-list',
  stressdetailchart: 'stress-detail-chart',
  summarydetail: 'summary-detail',
  userlist: 'user-list',
  langLogic: 'lang/logic',
}

const _LangDictionary: Translation[] = [
  { component: "Email", label: "パスワード設定", ja: "【あんしんミマモルナビ】パスワード設定のお願い", en: "Please set your MIMAMORUNAVI password", es: "Configure su contraseña de MIMAMORUNAVI" },
  { component: "ConditionRepository", label: "通常", ja: "通常", en: "Normal", es: "Normal" },
  { component: "ConditionRepository", label: "好調", ja: "好調", en: "Good", es: "Bien" },
  { component: "ConditionRepository", label: "やや好調", ja: "やや好調", en: "Slighlty good", es: "Casi bien" },
  { component: "ConditionRepository", label: "注意力低下", ja: "注意力低下", en: "Low alertness", es: "Atención reducida" },
  { component: "ConditionRepository", label: "やや注力低下", ja: "やや注力低下", en: "Alertness slightly low", es: "Atención levemente baja" },
  { component: "ConditionRepository", label: "不調", ja: "不調", en: "Unwell", es: "Mal" },
  { component: "ConditionRepository", label: "やや不調", ja: "やや不調", en: "Slightly unwell", es: "Levemente mal" },
  { component: "ConditionRepository", label: "高ストレス", ja: "高ストレス", en: "High stress", es: "Estrés alto" },
  { component: "ConditionRepository", label: "やや高ストレス", ja: "やや高ストレス", en: "Stress sligthly high", es: "Estrés levemente alto" },
  { component: "HirouRepository", label: "慢性疲労", ja: "慢性疲労", en: "Persistent fatigue", es: "Fatiga persistente" },
  { component: "HirouRepository", label: "前日の疲れ", ja: "前日の疲れ", en: "Fatigued from yesterday", es: "Fatiga del día anterior" },
  { component: "session", label: "ログインしました", ja: "ログインしました", en: "Logged in succesfully", es: "Ha iniciado sesión" },
  { component: "session", label: "ログインに失敗しました。Emailパスワードをお確かめください", ja: "ログインに失敗しました。Emailパスワードをお確かめください", en: "Failed to login. Please check your email password", es: "Error de inicio de sesion. Por favor verifique la contraseña de su correo electrónico" },
  { component: "session", label: "パスワードをお確かめください", ja: "パスワードをお確かめください", en: "Please confirm password", es: "Compruebe su contraseña" },
  { component: "session", label: "パスワードリセットに失敗しました。Emailをお確かめください", ja: "パスワードリセットに失敗しました。Emailをお確かめください", en: "The password reset link", es: "Enlace de reestablecimiento de contraseña" },
  { component: "session", label: "アドレスに送信いたしました", ja: "アドレスに送信いたしました", en: "has been sent to your address", es: "enviado a su dirección de correo" },
  { component: "session", label: "ログアウトに失敗しました。", ja: "ログアウトに失敗しました。", en: "Failed to reset password", es: "Fallo al reestablecer la contraseña" },
  { component: "session", label: "確認メールを送信しました。", ja: "確認メールを送信しました。", en: "", es: "" },
  { component: "session", label: "アカウントの作成に失敗しました。時間をおいて再度お試しください。", ja: "アカウントの作成に失敗しました。時間をおいて再度お試しください。", en: "Failed to log out", es: "Fallo al cerrar la sesión" },
  { component: "session", label: "パスワードリセットのためのリンクをEmailアドレスに送信いたしました", ja: "パスワードリセットのためのリンクをEmailアドレスに送信いたしました", en: "Confirmation mail sent", es: "Correo de confirmación enviado" },
  { component: "session", label: "パスワードリセットに失敗しましたパスワードリセットに失敗しました。Emailをお確かめください", ja: "パスワードリセットに失敗しましたパスワードリセットに失敗しました。Emailをお確かめください", en: "Failed to create an account", es: "Fallo al crear la cuenta" },
  { component: "session", label: "ログアウトしました。", ja: "ログアウトしました。", en: "Logged out successfuly", es: "Sesión cerrada con éxito" },
  { component: "alert-view", label: "完了", ja: "完了", en: "Completed", es: "Finalizado" },
  { component: "alert-view", label: "キャンセル", ja: "キャンセル", en: "Cancel", es: "Cancelar" },
  { component: "alert-view", label: "予定は削除されました", ja: "予定は削除されました", en: "Schedule was deleted", es: "Horario eliminado" },
  { component: "alert-view", label: "確認", ja: "確認", en: "Confirm", es: "Confirmar" },
  { component: "alert-view", label: "を削除します", ja: "を削除します", en: "has been deleted", es: "Será eliminado" },
  { component: "alert-view", label: "はい", ja: "はい", en: "Yes", es: "Si" },
  { component: "alert-view", label: "いいえ", ja: "いいえ", en: "No", es: "No" },
  { component: "alert-view", label: "の削除を成功しました", ja: "の削除を成功しました", en: "Deletion completed", es: "Eliminado con éxito" },
  { component: "alert-view", label: "あんしんミマモルナビのご利用ありがとうございました。今後ともあんしんミマモルナビをよろしくお願い致します。", ja: "あんしんミマモルナビのご利用ありがとうございました。今後ともあんしんミマモルナビをよろしくお願い致します。", en: "Thank you for using MIMAMORUNAVI. Thank you for your continued support of MIMAMORUNAVI", es: "Gracias por utilizar MIMAMORUNAVI. Gracias por su continuo apoyo a MIMAMORUNAVI." },
  { component: "alert-view", label: "ダウンロード", ja: "CSVダウンロード", en: "CSV download", es: "Descargar CSV" },
  { component: "alert-view", label: "日付を選択してください", ja: "日付を選択してください", en: "Please select a date", es: "Seleccione una fecha" },
  { component: "alert-view", label: "読み込み中...", ja: "読み込み中...", en: "Loading...", es: "Cargando..." },
  { component: "alert-view", label: "読み込み中", ja: "読み込み中", en: "Loading", es: "Cargando" },
  { component: "condition-icon", label: "通常", ja: "通常", en: "Normal", es: "Normal" },
  { component: "condition-icon", label: "好調", ja: "好調", en: "Good", es: "Bien" },
  { component: "condition-icon", label: "やや好調", ja: "やや好調", en: "Slighlty good", es: "Casi bien" },
  { component: "condition-icon", label: "不調", ja: "不調", en: "Unwell", es: "Mal" },
  { component: "condition-icon", label: "やや不調", ja: "やや不調", en: "Slightly unwell", es: "Levemente mal" },
  { component: "condition-icon", label: "高ストレス", ja: "高ストレス", en: "High stress", es: "Estrés alto" },
  { component: "condition-icon", label: "やや高ストレス", ja: "やや高ストレス", en: "Stress sligthly high", es: "Estrés levemente alto" },
  { component: "condition-icon", label: "注意力低下", ja: "注意力低下", en: "Low alertness", es: "Atención reducida" },
  { component: "condition-icon", label: "やや注意力低下", ja: "やや注意力低下", en: "Alertness sligthly low", es: "Atención levemente baja" },
  { component: "default-header", label: "ログアウト", ja: "ログアウト", en: "Logout", es: "Cerrar sesión" },
  { component: "default-header", label: "プロジェクト一覧", ja: "プロジェクト一覧", en: "Project list", es: "Lista de proyectos" },
  { component: "default-header", label: "コンディション確認", ja: "コンディション確認", en: "Condition check", es: "Comprobar condición" },
  { component: "default-header", label: "アラート振り返り", ja: "アラート振り返り", en: "Alert history", es: "Historial de alertas" },
  { component: "default-header", label: "熱中症リスク", ja: "熱中症リスク", en: "Heat Stroke Risk", es: "Riesgo de golpe de calor" },
  { component: "default-header", label: "疲労", ja: "疲労", en: "Fatigue", es: "Fatiga" },
  { component: "default-header", label: "マップ", ja: "マップ", en: "Map", es: "Mapa" },
  { component: "default-header", label: "端末一覧", ja: "端末一覧", en: "Devices", es: "Lista de dispositivos" },
  { component: "default-header", label: "アラート一覧", ja: "アラート一覧", en: "Alerts", es: "Lista de alertas" },
  { component: "default-header", label: "ユーザー管理", ja: "ユーザー管理", en: "User management", es: "Gestión de usuarios" },
  { component: "default-header", label: "管理者登録", ja: "管理者登録", en: "Registered managers", es: "Supervisores registrados" },
  { component: "alert-list", label: "アラート一覧", ja: "アラート一覧", en: "Alerts", es: "Lista de alertas" },
  { component: "alert-list", label: "管理者の名前", ja: "管理者の名前", en: "Manager's Name", es: "Nombre de supervisor" },
  { component: "alert-list", label: "装着者の名前", ja: "装着者の名前", en: "Equipped user's name", es: "Nombre del portador" },
  { component: "alert-list", label: "端末番号", ja: "端末番号", en: "Device number", es: "Número de dispositivo" },
  { component: "alert-list", label: "検出日時", ja: "検出日時", en: "Date of detection", es: "Fecha de detección" },
  { component: "alert-list", label: "アラートの種類", ja: "アラートの種類", en: "Alert type", es: "Tipo de alerta" },
  { component: "alert-summary", label: "疲労", ja: "疲労", en: "Fatigue", es: "Fatiga" },
  { component: "alert-summary", label: "熱中症", ja: "熱中症", en: "Heat stroke", es: "Golpe de calor" },
  { component: "alert-summary", label: "心拍異常", ja: "心拍異常", en: "Abnormal heart rate", es: "Frecuencia cardíaca anormal" },
  { component: "alert-summary", label: "日付: 2024-05-17", ja: "日付: 2024-05-17", en: "Date: 05-17-2024", es: "Fecha: 2024-05-17" },
  { component: "alert-summary", label: "時刻: 21:54:23", ja: "時刻: 21:54:23", en: "Time: 21:54:23", es: "Hora: 21:54:23" },
  { component: "alert-summary", label: "日付", ja: "日付", en: "Date", es: "Fecha" },
  { component: "alert-summary", label: "名前", ja: "名前", en: "Name", es: "Nombre" },
  { component: "alert-summary", label: "端末番号", ja: "端末番号", en: "Device number", es: "Número de dispositivo" },
  { component: "alert-summary", label: "アラート件数", ja: "アラート件数", en: "Number of alerts", es: "Cantidad de alertas" },
  { component: "alert-summary", label: "前日", ja: "前日", en: "Yesterday ", es: "Ayer: " },
  { component: "alert-summary", label: "当日", ja: "当日", en: "Today ", es: "Hoy: " },
  { component: "chat-list", label: "【端末情報】の端末情報", ja: "【端末情報】の端末情報", en: "Device information of【Device】", es: "Información del dispositivo [           ]" },
  { component: "chat-list", label: "計測時刻", ja: "計測時刻", en: "Measure time", es: "Hora de medición" },
  { component: "chat-list", label: "Cloud連携設定", ja: "Cloud連携設定", en: "Cloud Connection Settings", es: "Configuración conexión a Cloud" },
  { component: "chat-list", label: "端末の削除", ja: "端末の削除", en: "Delete device", es: "Eliminar dispositivo" },
  { component: "chat-list", label: "Download CSV", ja: "CSVダウンロード", en: "CSV download", es: "Descargar CSV" },
  { component: "chat-list", label: "【タイル名】の再表示", ja: "【タイル名】の再表示", en: "Show【Tile】again", es: "Volver a mostrar [             ]" },
  { component: "chat-list", label: "項目のリセット", ja: "項目のリセット", en: "Reset item", es: "Reiniciar elemento" },
  { component: "chat-list", label: "熱中症リスク", ja: "熱中症リスク", en: "Heat Stroke Risk", es: "Riesgo de golpe de calor" },
  { component: "chat-list", label: "上位へ表示", ja: "上位へ表示", en: "Move up", es: "Mover hacia arriba" },
  { component: "chat-list", label: "下位へ表示", ja: "下位へ表示", en: "Move down", es: "Mover hacia abajo" },
  { component: "chat-list", label: "表示を隠す", ja: "表示を隠す", en: "Hide", es: "Ocultar indicador" },
  { component: "chat-list", label: "上昇", ja: "上昇", en: "Ascending", es: "Aumento" },
  { component: "chat-list", label: "下降", ja: "下降", en: "Descending", es: "Disminución" },
  { component: "chat-list", label: "注意", ja: "注意", en: "Caution", es: "Precaución" },
  { component: "chat-list", label: "心拍数", ja: "心拍数", en: "Heart rate", es: "Frecuencia cardíaca" },
  { component: "chat-list", label: "危険", ja: "危険", en: "Danger", es: "Peligro" },
  { component: "chat-list", label: "暑さ指数", ja: "暑さ指数", en: "Heat Index", es: "índice de calor" },
  { component: "chat-list", label: "Heat Index", ja: "暑さ指数", en: "Heat Index", es: "índice de calor" },
  { component: "chat-list", label: "運動は原則禁止", ja: "運動は原則禁止", en: "Stop any Physical Exercise", es: "Pare toda actividad física" },
  { component: "chat-list", label: "厳重警戒", ja: "厳重警戒", en: "Extreme caution", es: "Precaución extrema" },
  { component: "chat-list", label: "激しい運動は中止", ja: "激しい運動は中止", en: "Stop Strenuous Exercise", es: "Evite actividad física extenuante" },
  { component: "chat-list", label: "警戒", ja: "警戒", en: "Warning", es: "Alerta" },
  { component: "chat-list", label: "積極的に休憩", ja: "積極的に休憩", en: "Please rest", es: "Descansar con urgencia" },
  { component: "chat-list", label: "積極的に水分補給", ja: "積極的に水分補給", en: "Please hydrate urgently", es: "Hidratarse con urgencia" },
  { component: "chat-list", label: "ほぼ安全", ja: "ほぼ安全", en: "Safe", es: "Estable" },
  { component: "chat-list", label: "適宜水分補給", ja: "適宜水分補給", en: "Hydrate appropriately", es: "Hidratarse apropiadamente" },
  { component: "chat-list", label: "天気予報", ja: "天気予報", en: "Weather Forecast", es: "Pronóstico del tiempo" },
  { component: "chat-list", label: "晴れ", ja: "晴れ", en: "Clear", es: "Despejado" },
  { component: "chat-list", label: "曇り", ja: "曇り", en: "Cloudy", es: "Nublado" },
  { component: "chat-list", label: "雨", ja: "雨", en: "Rainy", es: "Lluvia" },
  { component: "chat-list", label: "湿度", ja: "湿度", en: "Humidity", es: "Humedad" },
  { component: "chat-list", label: "心拍モニタリング", ja: "心拍モニタリング", en: "Heart Rate Monitoring", es: "Monitoreo de frecuencia cardíaca" },
  { component: "chat-list", label: "グラフ", ja: "グラフ", en: "Graph", es: "Gráfico" },
  { component: "chat-list", label: "統計値を表示", ja: "統計値を表示", en: "Show statistics", es: "Mostrar estadísticas" },
  { component: "chat-list", label: "眠気モニタリング", ja: "眠気モニタリング", en: "Sleepiness monitoring", es: "Monitoreo de nivel de sueño" },
  { component: "chat-list", label: "High sleepiness monitoring", ja: "眠気モニタリング", en: "Sleepiness monitoring", es: "Monitoreo de nivel de sueño" },
  { component: "chat-list", label: "強い眠気モニタリング", ja: "強い眠気モニタリング", en: "Highly sleepiness monitoring", es: "Monitoreo de nivel de sueño alto" },
  { component: "chat-list", label: "眠気度", ja: "眠気度", en: "Sleepiness", es: "Nivel de sueño" },
  { component: "chat-list", label: "眠気", ja: "眠気", en: "Sleepy", es: "Con sueño" },
  { component: "chat-list", label: "肌温度", ja: "肌温度", en: "Body temperature", es: "Temperatura corporal" },
  { component: "chat-list", label: "疲労モニタリング", ja: "疲労モニタリング", en: "Fatigue Monitoring", es: "Monitoreo de fatiga" },
  { component: "chat-list", label: "疲労度", ja: "疲労度", en: "Fatigue level", es: "Nivel de fatiga" },
  { component: "chat-list", label: "強い疲労感", ja: "強い疲労感", en: "Highly fatigued", es: "Nivel de fatiga alto" },
  { component: "chat-list", label: "疲労感", ja: "疲労感", en: "Fatigued", es: "fatiga" },
  { component: "chat-list", label: "気象モニタリング", ja: "気象モニタリング", en: "Weather Monitoring", es: "Monitoreo del clima" },
  { component: "chat-list", label: "気圧", ja: "気圧", en: "Atmospheric Pressure", es: "Presión atmosférica" },
  { component: "chat-list", label: "自律神経モニタリング", ja: "自律神経モニタリング", en: "Autonomic nervous system Monitoring", es: "Monitoreo sistema nervioso autónomo" },
  { component: "chat-list", label: "自律神経バランス", ja: "自律神経バランス", en: "Autonomic Nervous System Balance", es: "Balance del sistema nervioso autónomo" },
  { component: "chat-list", label: "計測中", ja: "計測中", en: "Measuring", es: "Midiendo" },
  { component: "chat-list", label: "運転モニタリング", ja: "運転モニタリング", en: "Driving monitoring", es: "Monitoreo de manejo" },
  { component: "chat-list", label: "運転スコア", ja: "運転スコア", en: "Driving score", es: "Puntaje de manejo" },
  { component: "chat-list", label: "不安定", ja: "不安定", en: "Unstable", es: "Inestable" },
  { component: "chat-list", label: "やや不安定", ja: "やや不安定", en: "Slightly unstable", es: "Ligeramente inestable" },
  { component: "chat-list", label: "通常", ja: "通常", en: "Normal", es: "Normal" },
  { component: "chat-list", label: "急加速/減速回数", ja: "急加速/減速回数", en: "Number of sudden accelerations/decelerations", es: "aceleraciones/desaceleraciones abruptas" },
  { component: "chat-list", label: "回", ja: "回", en: " times", es: " vez" },
  { component: "chat-list", label: "運転速度", ja: "運転速度", en: "Driving speed", es: "Velocidad de manejo" },
  { component: "chat-list", label: "測定中", ja: "測定中", en: "Measuring", es: "Midiendo" },
  { component: "chat-list", label: "運転継続時間", ja: "運転継続時間", en: "Continous driving time", es: "Tiempo de manejo continuo" },
  { component: "chat-list", label: "運転延べ時間", ja: "運転延べ時間", en: "Total driving time", es: "Tiempo total de manejo" },
  { component: "chat-list", label: "感情モニタリング", ja: "感情モニタリング", en: "Emotion Monitoring", es: "Monitoreo emocional" },
  { component: "chat-list", label: "今の感情", ja: "今の感情", en: "Current emotion", es: "Estado de ánimo actual" },
  { component: "chat-list", label: "ポ", ja: "ポ", en: "P", es: "P" },
  { component: "chat-list", label: "快", ja: "快", en: "C", es: "C" },
  { component: "chat-list", label: "快適", ja: "快適", en: "Comfort", es: "Confort" },
  { component: "chat-list", label: "ス", ja: "ス", en: "S", es: "E" },
  { component: "chat-list", label: "ストレス", ja: "ストレス", en: "Stress", es: "Estrés" },
  { component: "chat-list", label: "不", ja: "不", en: "D", es: "M" },
  { component: "chat-list", label: "不快", ja: "不快", en: "Discomfort", es: "Molestia" },
  { component: "chat-list", label: "リ", ja: "リ", en: "R", es: "R" },
  { component: "chat-list", label: "リラックス", ja: "リラックス", en: "Relaxation", es: "Relajación" },
  { component: "chat-list", label: "心拍変動モニタリング", ja: "心拍変動モニタリング", en: "Heart Rate Variation Monitoring", es: "Monitoreo de variabilidad del ritmo cardíaco" },
  { component: "chat-list", label: "快適", ja: "快適", en: "Comfort", es: "Confort" },
  { component: "chat-list", label: "覚醒", ja: "覚醒", en: "Awake", es: "Despierto" },
  { component: "chat-list", label: "端末の削除", ja: "端末の削除", en: "Delete device", es: "Eliminar dispositivo" },
  { component: "chat-list", label: "この端末を削除します", ja: "この端末を削除します", en: "This device will be deleted", es: "Este dispositivo será eliminado" },
  { component: "chat-list", label: "よろしいですか", ja: "よろしいですか", en: "¿Continue?", es: "¿Continuar?" },
  { component: "chat-list", label: "はい", ja: "はい", en: "Yes", es: "Si" },
  { component: "chat-list", label: "いいえ", ja: "いいえ", en: "No", es: "No" },
  { component: "chat-list", label: "注意", ja: "注意", en: "Caution", es: "Precaución" },
  { component: "chat-list", label: "危険", ja: "危険", en: "Danger", es: "Peligro" },
  { component: "chat-list", label: "疲労モニタリング", ja: "疲労モニタリング", en: "Fatigue Monitoring", es: "Monitoreo de fatiga" },
  { component: "chat-list", label: "平均の平均", ja: "平均の平均", en: "Average", es: "Media" },
  { component: "chat-list", label: "標準偏差の平均", ja: "標準偏差の平均", en: "Standard deviation average", es: "Media de la desviación estandar" },
  { component: "chat-list", label: "心拍モニタリング", ja: "心拍モニタリング", en: "Heart Rate Monitoring", es: "Monitoreo de frecuencia cardíaca" },
  { component: "chat-list", label: "心拍数", ja: "心拍数", en: "Heart rate", es: "Frecuencia cardíaca" },
  { component: "chat-list", label: "安静時心拍数", ja: "安静時心拍数", en: "Resting Heart Rate", es: "Frecuencia cardíaca en reposo" },
  { component: "chat-list", label: "平均安静時心拍数", ja: "平均安静時心拍数", en: "Resting heart rate average", es: "Media de frecuencia cardíaca en reposo" },
  { component: "chat-list", label: "です", ja: "です", en: "", es: "" },
  { component: "chat-list", label: "判定なし", ja: "判定なし", en: "No data", es: "Sin evaluar" },
  { component: "chat-list", label: "運動は原則禁止", ja: "運動は原則禁止", en: "Stop any Physical Exercise", es: "Pare toda actividad física" },
  { component: "chat-list", label: "厳重警戒", ja: "厳重警戒", en: "Extreme caution", es: "Precaución extrema" },
  { component: "chat-list", label: "激しい運動は中止", ja: "激しい運動は中止", en: "Stop Strenuous Exercise", es: "Evite actividad física extenuante" },
  { component: "chat-list", label: "警戒", ja: "警戒", en: "Warning", es: "Alerta" },
  { component: "chat-list", label: "積極的に休憩", ja: "積極的に休憩", en: "Please rest", es: "Descansar con urgencia" },
  { component: "chat-list", label: "積極的に水分補給", ja: "積極的に水分補給", en: "Please hydrate urgently", es: "Hidratarse con urgencia" },
  { component: "chat-list", label: "ほぼ安全", ja: "ほぼ安全", en: "Safe", es: "Estable" },
  { component: "chat-list", label: "適宜水分補給", ja: "適宜水分補給", en: "Hydrate appropriately", es: "Hidratarse apropiadamente" },
  { component: "chat-list", label: "高心拍", ja: "高心拍", en: "High heart rate", es: "Alta frecuencia cardíaca" },
  { component: "chat-list", label: "分以上高い心拍数が続いています", ja: "分以上高い心拍数が続いています", en: "Minutes of continuous high heart rate", es: "Minutos de alta frecuencia cardíaca" },
  { component: "chat-list", label: "低心拍", ja: "低心拍", en: "Low heart rate", es: "Baja frecuencia cardíaca" },
  { component: "chat-list", label: "分以上低い心拍数が続いています", ja: "分以上低い心拍数が続いています", en: "Minutes of continuous low heart rate", es: "Minutos de baja frecuencia cardíaca" },
  { component: "chat-list", label: "不整脈", ja: "不整脈", en: "Arrhythmia", es: "Arritmia" },
  { component: "chat-list", label: "不整脈の発生回数が多くなっています", ja: "不整脈の発生回数が多くなっています", en: "High instances of arrhythmia", es: "Numerosos casos de arritmia" },
  { component: "chat-list", label: "心拍計算", ja: "心拍計算", en: "Heart rate calculation", es: "Calculo de frecuencia cardíaca" },
  { component: "chat-list", label: "正常", ja: "正常", en: "Stable", es: "Regular" },
  { component: "chat-list", label: "選択なし", ja: "選択なし", en: "Not selected", es: "Sin seleccionar" },
  { component: "chat-list", label: "クラウド連携先を設定", ja: "クラウド連携先を設定", en: "Cloud connection settings", es: "Configurar conexión a Cloud" },
  { component: "chat-list", label: "プロジェクト", ja: "プロジェクト", en: "Project", es: "Proyecto" },
  { component: "chat-list", label: "更新を完了しました", ja: "更新を完了しました", en: "Update completed", es: "Actualización terminada" },
  { component: "chat-list", label: "データ件数は", ja: "データ件数は", en: "There are", es: "Existen" },
  { component: "chat-list", label: "件です", ja: "件です", en: "「」number of data sets", es: "[] casos" },
  { component: "chat-list", label: "熱中症リスク", ja: "熱中症リスク", en: "Heat Stroke Risk", es: "Riesgo de golpe de calor" },
  { component: "chat-list", label: "心拍モニタリング", ja: "心拍モニタリング", en: "Heart Rate Monitoring", es: "Monitoreo de frecuencia cardíaca" },
  { component: "chat-list", label: "眠気モニタリング", ja: "眠気モニタリング", en: "Sleepiness monitoring", es: "Monitoreo de nivel de sueño" },
  { component: "chat-list", label: "疲労モニタリング", ja: "疲労モニタリング", en: "Fatigue Monitoring", es: "Monitoreo de fatiga" },
  { component: "chat-list", label: "気象モニタリング", ja: "気象モニタリング", en: "Weather Monitoring", es: "Monitoreo del clima" },
  { component: "chat-list", label: "自律神経モニタリング", ja: "自律神経モニタリング", en: "Autonomic nervous system Monitoring", es: "Monitoreo sistema nervioso autónomo" },
  { component: "chat-list", label: "運転モニタリング", ja: "運転モニタリング", en: "Driving monitoring", es: "Monitoreo de manejo" },
  { component: "chat-list", label: "感情モニタリング", ja: "感情モニタリング", en: "Emotion Monitoring", es: "Monitoreo emocional" },
  { component: "chat-list", label: "心拍変動モニタリング", ja: "心拍変動モニタリング", en: "Heart Rate Variation Monitoring", es: "Monitoreo de variabilidad del ritmo cardíaco" },
  { component: "chat-list", label: "肌温度：上昇", ja: "肌温度：上昇", en: "Skin temperature: rise", es: "Temperatura de la piel: aumento" },
  { component: "chat-list", label: "アプリバージョン", ja: "アプリバージョン", en: "App Version", es: "Versión de aplicación" },
  { component: "chat-list", label: "デバイス名", ja: "デバイス名", en: "Device Name", es: "Nombre del dispositivo" },
  { component: "chat-list", label: "本日の計測データは０件です", ja: "本日の計測データは０件です", en: "Today's measurement data is 0 items.", es: "Los datos de medición de hoy son 0 elementos." },
  { component: "condition-detail", label: "コンディション判定", ja: "コンディション判定", en: "Condition evaluation", es: "Evaluación de la condición" },
  { component: "condition-detail", label: "心拍数", ja: "心拍数", en: "Heart rate", es: "Frecuencia cardíaca" },
  { component: "condition-detail", label: "心拍変動スコア", ja: "心拍変動スコア", en: "Heart rate variation score", es: "Calificación de variabilidad de frecuencia cardíaca" },
  { component: "condition-detail", label: "自律神経活動量スコア", ja: "自律神経活動量スコア", en: "Autonomic nervous system activity score", es: "Calificación de actividad del sistema nervioso autónomo" },
  { component: "condition-detail", label: "日周性疲労", ja: "日周性疲労", en: "Daytime fatigue", es: "Fatiga diaria" },
  { component: "condition-detail", label: "強い疲労感", ja: "強い疲労感", en: "Highly fatigued", es: "Nivel de fatiga alto" },
  { component: "condition-detail", label: "眠気スコア", ja: "眠気スコア", en: "Sleepiness score", es: "Calificación del sueño" },
  { component: "condition-detail", label: "ストレススコア", ja: "ストレススコア", en: "Stress score", es: "Calificación de estrés" },
  { component: "condition-detail", label: "リラックススコア", ja: "リラックススコア", en: "Relaxation score", es: "Calificación de relajación" },
  { component: "condition-detail", label: "熱中症リスク", ja: "熱中症リスク", en: "Heat Stroke Risk", es: "Riesgo de golpe de calor" },
  { component: "condition-detail", label: "歩数", ja: "歩数", en: "Steps", es: "Pasos" },
  { component: "condition-detail", label: "最高気温", ja: "最高気温", en: "Maximum temperature", es: "Temperatura máxima" },
  { component: "condition-detail", label: "最低湿度", ja: "最低湿度", en: "Minimum temperature", es: "Temperatura mínima" },
  { component: "condition-detail", label: "さらに表示", ja: "さらに表示", en: "Show more", es: "Mostrar más" },
  { component: "condition-detail", label: "あり", ja: "あり", en: "Yes", es: "Si" },
  { component: "condition-detail", label: "なし", ja: "なし", en: "No", es: "No" },
  { component: "condition-detail", label: "心拍数スコア", ja: "心拍数スコア", en: "Heart rate score", es: "Puntuación de frecuencia cardíaca" },
  { component: "condition-detail", label: "自律神経バランススコア", ja: "自律神経バランススコア", en: "Autonomic nerve balance score", es: "Puntuación de equilibrio nervioso autónomo" },
  { component: "condition-detail", label: "心拍変動スコア", ja: "心拍変動スコア", en: "Heart rate variability score", es: "Puntuación de variabilidad de la frecuencia cardíaca" },
  { component: "condition-detail", label: "リラックススコア", ja: "リラックススコア", en: "Relax score", es: "Relajarse puntuación" },
  { component: "condition-detail", label: "海面気圧", ja: "海面気圧", en: "Sea ​​level pressure", es: "Presión al nivel del mar" },
  { component: "condition-list", label: "日付", ja: "日付", en: "Date", es: "Fecha" },
  { component: "condition-list", label: "名前", ja: "名前", en: "Name", es: "Nombre" },
  { component: "condition-list", label: "端末番号", ja: "端末番号", en: "Device number", es: "Número de dispositivo" },
  { component: "condition-list", label: "前日", ja: "前日", en: "Yesterday", es: "Ayer" },
  { component: "condition-list", label: "当日", ja: "当日", en: "Same day", es: "Hoy" },
  { component: "condition-list", label: "日", ja: "日", en: "Sun", es: "DO" },
  { component: "condition-list", label: "月", ja: "月", en: "Mon", es: "LU" },
  { component: "condition-list", label: "火", ja: "火", en: "Tue", es: "MA" },
  { component: "condition-list", label: "水", ja: "水", en: "Wed", es: "MI" },
  { component: "condition-list", label: "木", ja: "木", en: "Thu", es: "JU" },
  { component: "condition-list", label: "金", ja: "金", en: "Fri", es: "VI" },
  { component: "condition-list", label: "土", ja: "土", en: "Sat", es: "SA" },
  { component: "condition-list", label: "undefined", ja: "", en: "", es: "" },
  { component: "condition-list", label: "-", ja: "-", en: "-", es: "-" },
  { component: "device-list", label: "端末一覧", ja: "端末一覧", en: "Devices", es: "Lista de dispositivos" },
  { component: "device-list", label: "稼働中の端末のみ表示", ja: "稼働中の端末のみ表示", en: "Show active devices only", es: "Solo dispositivos operativos" },
  { component: "device-list", label: "日付", ja: "日付", en: "Date", es: "Fecha" },
  { component: "device-list", label: "時刻", ja: "時刻", en: "Time", es: "Hora" },
  { component: "device-list", label: "日時", ja: "日時", en: "Date and time", es: "Fecha y hora" },
  { component: "device-list", label: "名前", ja: "名前", en: "Name", es: "Nombre" },
  { component: "device-list", label: "稼働中", ja: "稼働中", en: "Active", es: "Operativo" },
  { component: "device-list", label: "緊急連絡先1", ja: "緊急連絡先", en: "Emergency contact", es: "Contacto de emergencia" },
  { component: "device-list", label: "緊急連絡先2", ja: "", en: "", es: "" },
  { component: "device-list", label: "端末番号1", ja: "端末番号", en: "Device number", es: "Número de dispositivo" },
  { component: "device-list", label: "端末番号2", ja: "", en: "", es: "" },
  { component: "device-list", label: "電池残量", ja: "電池残量", en: "Remaining battery", es: "Bateria restante" },
  { component: "device-list", label: "種類", ja: "種類", en: "Type", es: "Tipo" },
  { component: "device-list", label: "心拍数", ja: "心拍数", en: "Heart rate", es: "Frecuencia cardíaca" },
  { component: "device-list", label: "暑さ指数1", ja: "暑さ指数", en: "Heat Index", es: "índice de calor" },
  { component: "device-list", label: "暑さ指数2", ja: "", en: "", es: "" },
  { component: "device-list", label: "心拍判定1", ja: "心拍判定", en: "Heart rate evaluation", es: "Evaluación de frecuencia cardíaca" },
  { component: "device-list", label: "心拍判定2", ja: "", en: "", es: "" },
  { component: "device-list", label: "アラート", ja: "アラート", en: "Alert", es: "Historial de" },
  { component: "device-list", label: "履歴", ja: "履歴", en: "History", es: "Alerta" },
  { component: "device-list", label: "熱中症アラート1", ja: "熱中症アラート1", en: "Heat stroke alert 1", es: "Golpe de calor 1" },
  { component: "device-list", label: "熱中症注意アラート", ja: "熱中症注意アラート", en: "Heat stroke warning alert", es: "Alerta de golpe de calor" },
  { component: "device-list", label: "熱中症危険アラート", ja: "熱中症危険アラート", en: "Heat stroke danger alert", es: "Alerta de peligro de golpe de calor" },
  { component: "device-list", label: "熱中症アラート2", ja: "", en: "", es: "" },
  { component: "device-list", label: "疲労アラート1", ja: "疲労アラート1", en: "Fatigue alert 1", es: "Fatiga 1" },
  { component: "device-list", label: "疲労アラート2", ja: "", en: "", es: "" },
  { component: "device-list", label: "眠気アラート1", ja: "眠気アラート", en: "Sleepiness alert", es: "Sueño" },
  { component: "device-list", label: "眠気アラート2", ja: "", en: "", es: "" },
  { component: "device-list", label: "アラート履歴1", ja: "アラート履歴", en: "Alert history", es: "Historial de alertas" },
  { component: "device-list", label: "アラート履歴2", ja: "", en: "", es: "" },
  { component: "device-list", label: "暑さ指数", ja: "暑さ指数", en: "Heat Index", es: "índice de calor" },
  { component: "device-list", label: "心拍判定", ja: "心拍判定", en: "Heart rate evaluation", es: "Evaluación de frecuencia cardíaca" },
  { component: "device-list", label: "熱中症アラート", ja: "熱中症アラート", en: "Heat stroke alert", es: "Golpe de calor" },
  { component: "device-list", label: "疲労アラート", ja: "疲労アラート", en: "Fatigue alert", es: "Fatiga" },
  { component: "device-list", label: "眠気アラート", ja: "眠気アラート", en: "Sleepiness alert", es: "Sueño" },
  { component: "device-list", label: "アラート履歴", ja: "アラート履歴", en: "Alert history", es: "Historial de alertas" },
  { component: "device-list", label: "地図", ja: "地図", en: "Map", es: "Mapa" },
  { component: "device-list", label: "詳細", ja: "詳細", en: "Details", es: "Detalles" },
  { component: "device-list", label: "集計", ja: "集計", en: "Summary", es: "Total" },
  { component: "device-list", label: "少", ja: "少", en: "Low", es: "Baja" },
  { component: "device-list", label: "中", ja: "中", en: "Medium", es: "Media" },
  { component: "device-list", label: "多", ja: "多", en: "High", es: "Alta" },
  { component: "device-list", label: "登録なし", ja: "登録なし", en: "No record", es: "Sin registrar" },
  { component: "device-list", label: "閉じる", ja: "閉じる", en: "Close", es: "Cerrar" },
  { component: "device-list", label: "電話番号が設定されていません", ja: "電話番号が設定されていません", en: "Phone number not registered", es: "No se ha registrado un número telefónico" },
  { component: "device-list", label: "電話番号は○○です", ja: "電話番号は○○です", en: "Phone number is ○○", es: "El número telefónico es [  ]" },
  { component: "device-list", label: "装着中のユーザを選択してください", ja: "装着中のユーザを選択してください", en: "Please select a wearing user", es: "Elija al usuario (portador)" },
  { component: "device-list", label: "選択する", ja: "選択する", en: "Select", es: "Elegir" },
  { component: "device-list", label: "クリア", ja: "クリア", en: "Clear", es: "Limpiar" },
  { component: "device-list", label: "強い眠気", ja: "強い眠気", en: "Highly sleepy", es: "Sueño intenso" },
  { component: "device-list", label: "強い疲労", ja: "強い疲労", en: "HIghly fatigued", es: "Fatiga intensa" },
  { component: "device-list", label: "アラートを検出しました", ja: "アラートを検出しました", en: "Alert detected", es: "Alerta detectada" },
  { component: "device-list", label: "強い眠気アラートを検出しました", ja: "強い眠気アラートを検出しました", en: "A highly sleepy alert has been detected", es: "Se ha detectado una fuerte alerta de somnolencia" },
  { component: "device-list", label: "転倒モニタリング", ja: "転倒モニタリング", en: "Fall monitoring", es: "Monitoreo de caídas" },
  { component: "device-list", label: "転倒アラート1", ja: "転倒", en: "Fall", es: "Monitoreo" },
  { component: "device-list", label: "転倒あり", ja: "転倒あり", en: "There was a fall", es: "Hubo una caida" },
  { component: "device-list", label: "転倒アラート2", ja: "アラート", en: "monitoring", es: "de caídas" },
  { component: "device-list", label: "定型メッセージ", ja: "定型メッセージ", en: "Standard message", es: "Mensaje estándar" },
  { component: "device-list", label: "送信", ja: "送信", en: "Send", es: "Enviar" },
  { component: "device-list", label: "メッセージを選択", ja: "メッセージを選択", en: "Select message", es: "Seleccionar mensaje" },
  { component: "device-list", label: "メッセージを選択してください", ja: "メッセージを選択してください", en: "Select message", es: "Seleccionar mensaje" },
  { component: "device-list", label: "メッセージを送信しました", ja: "メッセージを送信しました", en: "Message sent", es: "Mensaje enviado" },
  { component: "hirou-list", label: "心拍異常", ja: "心拍異常", en: "Abnormal heart rate", es: "Frecuencia cardíaca anormal" },
  { component: "hirou-list", label: "熱中症", ja: "熱中症", en: "Heat stroke", es: "Golpe de calor" },
  { component: "hirou-list", label: "疲労", ja: "疲労", en: "Fatigue", es: "Fatiga" },
  { component: "hirou-list", label: "日付", ja: "日付", en: "Date", es: "Fecha" },
  { component: "hirou-list", label: "名前", ja: "名前", en: "Name", es: "Nombre" },
  { component: "hirou-list", label: "端末番号", ja: "端末番号", en: "Device number", es: "Número de dispositivo" },
  { component: "hirou-list", label: "疲労日数", ja: "疲労日数", en: "Fatigue days", es: "Días de fatiga" },
  { component: "hirou-list", label: "前日", ja: "前日", en: "Yesterday", es: "Ayer" },
  { component: "hirou-list", label: "当日", ja: "当日", en: "Same day", es: "Hoy" },
  { component: "hirou-list", label: "日周性疲労", ja: "日周性疲労", en: "Daytime fatigue", es: "Fatiga diaria" },
  { component: "hirou-list", label: "慢性疲労", ja: "慢性疲労", en: "Persistent fatigue", es: "Fatiga persistente" },
  { component: "hirou-list", label: "心拍異常", ja: "心拍異常", en: "Abnormal heart rate", es: "Frecuencia cardíaca anormal" },
  { component: "hirou-list", label: "熱中症", ja: "熱中症", en: "Heat stroke", es: "Golpe de calor" },
  { component: "hirou-list", label: "疲労", ja: "疲労", en: "Fatigue", es: "Fatiga" },
  { component: "hirou-list", label: "日", ja: "日", en: "Sun", es: "DO" },
  { component: "hirou-list", label: "月", ja: "月", en: "Mon", es: "LU" },
  { component: "hirou-list", label: "火", ja: "火", en: "Tue", es: "MA" },
  { component: "hirou-list", label: "水", ja: "水", en: "Wed", es: "MI" },
  { component: "hirou-list", label: "木", ja: "木", en: "Thu", es: "JU" },
  { component: "hirou-list", label: "金", ja: "金", en: "Fri", es: "VI" },
  { component: "hirou-list", label: "土", ja: "土", en: "Sat", es: "SA" },
  { component: "item-detail", label: "開始日が終了日よりも後になっています", ja: "開始日が終了日よりも後になっています", en: "Start date is later than end date", es: "La fecha de inicio es posterior a la fecha de finalización" },
  { component: "item-detail-chart", label: "【端末ID】の心拍数の情報", ja: "【端末ID】の心拍数の情報", en: "Heart rate data from 　「Device ID」", es: "Detalles frecuencia cardíaca [ID dispositivo]" },
  { component: "item-detail-chart", label: "最新の計測時刻", ja: "最新の計測時刻", en: "Last measure time", es: "Hora de la última medición" },
  { component: "item-detail-chart", label: "心拍数の推移", ja: "心拍数の推移", en: "Heart rate variation", es: "Evolución de frecuencia cardíaca" },
  { component: "item-detail-chart", label: "心拍数", ja: "心拍数", en: "Heart rate", es: "Frecuencia cardíaca" },
  { component: "item-detail-chart", label: "平均値", ja: "平均値", en: "Average", es: "Media" },
  { component: "item-detail-chart", label: "中央値", ja: "中央値", en: "Median", es: "Mediana" },
  { component: "login", label: "アカウントをお持ちでない方は", ja: "アカウントをお持ちでない方は", en: "Users without an account", es: "Usuarios sin una cuenta:" },
  { component: "login", label: "こちらから", ja: "こちらから", en: "Here", es: "aquí" },
  { component: "login", label: "利用規約", ja: "利用規約", en: "Privacy policy", es: "Política de privacidad" },
  { component: "login", label: "プライバシーポリシー", ja: "プライバシーポリシー", en: "terms of service", es: "condiciones de uso" },
  { component: "login", label: "and", ja: "と", en: "and", es: "y" },
  { component: "login", label: "ログイン情報入力", ja: "ログイン情報入力", en: "Enter log in credentials", es: "Inicio de sesión" },
  { component: "login", label: "メール", ja: "メール", en: "E-mail", es: "Correo electrónico" },
  { component: "login", label: "必須の項目です", ja: "必須の項目です", en: "Required field", es: "Campo necesario" },
  { component: "login", label: "無効な", ja: "無効な", en: "Invaild", es: "Correo electrónico" },
  { component: "login", label: "メールアドレスです", ja: "メールアドレスです", en: "E-mail adress", es: "inválido" },
  { component: "login", label: "Eメール", ja: "Eメール", en: "Email", es: "Correo electrónico" },
  { component: "login", label: "パスワード", ja: "パスワード", en: "Password", es: "Contraseña" },
  { component: "login", label: "無効なパスワードです", ja: "無効なパスワードです", en: "Invalid password", es: "Contraseña inválida" },
  { component: "login", label: "パスワードをお忘れですか", ja: "パスワードをお忘れですか", en: "Forgot your password?", es: "Olvidé mi contraseña" },
  { component: "login", label: "ログイン", ja: "ログイン", en: "Log in", es: "Entrar" },
  { component: "manager-list", label: "管理者一覧", ja: "管理者一覧", en: "List of administrators", es: "Lista de supervisores" },
  { component: "manager-list", label: "新規登録", ja: "新規登録", en: "Register", es: "Añadir" },
  { component: "manager-list", label: "名前", ja: "名前", en: "Name", es: "Nombre" },
  { component: "manager-list", label: "所属", ja: "所属", en: "Afiliation", es: "Afiliación" },
  { component: "manager-list", label: "電話番号", ja: "電話番号", en: "Phone number", es: "Número telefónico" },
  { component: "manager-list", label: "ユーザー", ja: "ユーザー", en: "Users", es: "Usuario " },
  { component: "manager-list", label: "編集", ja: "編集", en: "Edit", es: "Editar" },
  { component: "manager-list", label: "削除", ja: "削除", en: "Delete", es: "Eliminar" },
  { component: "manager-list", label: "更新", ja: "更新", en: "Update", es: "Actualizar" },
  { component: "manager-list", label: "ユーザ情報を登録", ja: "ユーザ情報を登録", en: "Register user information", es: "Registrar datos de usuario" },
  { component: "manager-list", label: "メール", ja: "メール", en: "E-mail", es: "Correo electrónico" },
  { component: "manager-list", label: "名前の項目が未入力です", ja: "名前の項目が未入力です", en: "No name has been entered", es: "Nombre sin ingresar" },
  { component: "manager-list", label: "電話番号の項目が未入力です", ja: "電話番号の項目が未入力です", en: "No phone number has been entered", es: "Número telefónico sin ingresar" },
  { component: "manager-list", label: "の項目が未入力です", ja: "の項目が未入力です", en: "Has not been entered", es: "Sin ingresar" },
  { component: "manager-list", label: "登録を完了しました", ja: "登録を完了しました", en: "Registration completed", es: "Registro finalizado" },
  { component: "manager-list", label: "更新を完了しました", ja: "更新を完了しました", en: "Update completed", es: "Actualización terminada" },
  { component: "manager-list", label: "管理者情報", ja: "管理者情報", en: "administrators", es: "supervisores" },
  { component: "manager-list", label: "既に使用されているEメールアドレスです", ja: "既に使用されているEメールアドレスです", en: "This email is already registered", es: "Este correo electrónico ya está registrado" },
  { component: "map", label: "【端末ID】の位置情報", ja: "【端末ID】の位置情報", en: "Location of 「Device ID」", es: "Localización de [ID dispositivo]" },
  { component: "map", label: "測位時刻", ja: "測位時刻", en: "Time of location", es: "Hora de localización" },
  { component: "nettyusho-risk", label: "日付", ja: "日付", en: "Date", es: "Fecha" },
  { component: "nettyusho-risk", label: "名前", ja: "名前", en: "Name", es: "Nombre" },
  { component: "nettyusho-risk", label: "端末番号", ja: "端末番号", en: "Device number", es: "Número de dispositivo" },
  { component: "nettyusho-risk", label: "アラート件数", ja: "アラート件数", en: "Number of alerts", es: "Cantidad de alertas" },
  { component: "nettyusho-risk", label: "前日", ja: "前日", en: "Yesterday", es: "Ayer" },
  { component: "nettyusho-risk", label: "当日", ja: "当日", en: "Same day", es: "Hoy" },
  { component: "nettyusho-risk", label: "心拍異常", ja: "心拍異常", en: "Abnormal heart rate", es: "Frecuencia cardíaca anormal" },
  { component: "nettyusho-risk", label: "熱中症", ja: "熱中症", en: "Heat stroke", es: "Golpe de calor" },
  { component: "nettyusho-risk", label: "疲労", ja: "疲労", en: "Fatigue", es: "Fatiga" },
  { component: "nettyusho-risk", label: "日", ja: "日", en: "Sun", es: "DO" },
  { component: "nettyusho-risk", label: "月", ja: "月", en: "Mon", es: "LU" },
  { component: "nettyusho-risk", label: "火", ja: "火", en: "Tue", es: "MA" },
  { component: "nettyusho-risk", label: "水", ja: "水", en: "Wed", es: "MI" },
  { component: "nettyusho-risk", label: "木", ja: "木", en: "Thu", es: "JU" },
  { component: "nettyusho-risk", label: "金", ja: "金", en: "Fri", es: "VI" },
  { component: "nettyusho-risk", label: "土", ja: "土", en: "Sat", es: "SA" },
  { component: "nettyusho-risk-chart", label: "【端末ID】の熱中症リスク度の情報", ja: "【端末ID】の熱中症リスク度の情報", en: "Heat stroke risk level of 「Device ID」", es: "nivel de riesgo de golpe de calor de [ID dispositivo]" },
  { component: "nettyusho-risk-chart", label: "熱中症リスク度の推移", ja: "熱中症リスク度の推移", en: "Heat stroke risk level variation", es: "Evolución del nivel de riesgo de golpe de calor" },
  { component: "nettyusho-risk-chart", label: "熱中症リスク度", ja: "熱中症リスク度", en: "Heat stroke risk level", es: "Nivel de riesgo de golpe de calor" },
  { component: "page-not-found", label: "お探しのページは見つかりませんでした", ja: "お探しのページは見つかりませんでした", en: "Page not found", es: "No se encontró la página" },
  { component: "page-not-found", label: "アクセスしたアドレスが正しいことを確認してください", ja: "アクセスしたアドレスが正しいことを確認してください", en: "Please confirm your address", es: "Compruebe que la dirección ingresada es correcta" },
  { component: "page-not-found", label: "ホームに戻る", ja: "ホームに戻る", en: "Return to home screen", es: "Página principal" },
  { component: "project-list", label: "プロジェクト一覧", ja: "プロジェクト一覧", en: "Project list", es: "Lista de proyectos" },
  { component: "project-list", label: "プロジェクトの名前", ja: "プロジェクトの名前", en: "Project name", es: "Nombre del proyecto" },
  { component: "project-list", label: "ドメインの名称", ja: "ドメインの名称", en: "Domain name", es: "Nombre de dominio" },
  { component: "project-list", label: "管理画面コード", ja: "管理画面コード", en: "Admin code", es: "Código de pantalla de administrador" },
  { component: "project-list", label: "作成者", ja: "作成者", en: "Creator", es: "Creador" },
  { component: "project-list", label: "作成日", ja: "作成日", en: "Created date", es: "Fecha de creación" },
  { component: "project-list", label: "閲覧", ja: "閲覧", en: "View", es: "Vista" },
  { component: "project-list", label: "削除", ja: "削除", en: "Delete", es: "Borrar" },
  { component: "project-list", label: "プロジェクトの情報", ja: "プロジェクトの情報", en: "Project information", es: "Información del proyecto" },
  { component: "project-list", label: "空のプロジェクトがありません", ja: "エラーが発生しました", en: "An error has occurred", es: "Se ha producido un error" },
  { component: "project-list", label: "プロジェクトを登録", ja: "プロジェクトを登録", en: "Register a project", es: "Registrar un proyecto" },
  { component: "project-list", label: "プロジェクトの名称の項目が未入力です", ja: "プロジェクトの名称の項目が未入力です", en: "The project name field has not been entered.", es: "No se ha ingresado el campo del nombre del proyecto." },
  { component: "project-list", label: "管理画面コードは4桁の数字でなければなりません", ja: "管理画面コードは4桁の数字でなければなりません", en: "Company code must be 4 digits", es: "El código de empresa debe tener 4 dígitos." },
  { component: "project-list", label: "管理画面は4桁の数字でなければなりません", ja: "管理画面コードは4桁の数字でなければなりません", en: "Admin code must be 4 digits", es: "El código de gestión debe tener 4 dígitos." },
  { component: "project-list", label: "管理画面コードが重複しています", ja: "管理画面コードが重複しています", en: "Duplicate admin screen code", es: "Código de pantalla de administrador duplicado" },
  { component: "project-list", label: "管理画面が重複しています", ja: "管理画面コードが重複しています", en: "Duplicate admin code", es: "Código de pantalla de administrador duplicado" },
  { component: "project-list", label: "登録を完了しました", ja: "登録を完了しました", en: "Registration completed", es: "Registro completado" },
  { component: "project-list", label: "ログインアカウント", ja: "ログインアカウント", en: "Login Account", es: "Cuenta de inicio de sesión" },
  { component: "project-list", label: "ログインパスワード", ja: "ログインパスワード", en: "Login Password", es: "Contraseña de inicio de sesión" },
  { component: "project-list", label: "URL", ja: "URL", en: "URL", es: "URL" },
  { component: "project-list", label: "URLのリクエスト", ja: "URLのリクエスト", en: "Request URL", es: "Solicitar URL" },
  { component: "project-list", label: "新しいドメインの名称", ja: "新しいドメインの名称", en: "New domain name", es: "Nuevo nombre de dominio" },
  { component: "project-list", label: "ドメイン変更のリクエスト", ja: "ドメイン変更のリクエスト", en: "Request new domain", es: "Solicitar Domain" },
  { component: "project-list", label: "現在のドメイン", ja: "現在のドメイン", en: "Current domain name", es: "Nombre de dominio" },
  { component: "project-list", label: "新しいドメイン", ja: "新しいドメイン", en: "New domain name", es: "Nuevo nombre de dominio" },
  { component: "project-list", label: "リクエスト", ja: "リクエスト", en: "Request", es: "Pedido" },
  { component: "project-list", label: "ステータス", ja: "ステータス", en: "Status", es: "Estado" },
  { component: "project-list", label: "テスト中", ja: "テスト中", en: "Under test", es: "Bajo prueba" },
  { component: "project-list", label: "運用中", ja: "運用中", en: "In operation", es: "En funcionamiento" },
  { component: "project-list", label: "終了", ja: "終了", en: "End", es: "Fin" },
  { component: "project-list", label: "表示を絞り込み", ja: "表示を絞り込み", en: "Filter", es: "Filtrar" },
  { component: "project-list", label: "更新しました", ja: "更新しました",en: "Updated", es: "Actualizado." },
  {
    component: "project-list", label: "リクエストを送信しました。完了次第連絡いたしますのでしばらくお待ち下さい。", ja: "リクエストを送信しました。完了次第連絡いたしますのでしばらくお待ち下さい。", en: "Your request has been submitted. We will contact you as soon as it is completed, so please wait for a while."
    , es: "Su solicitud ha sido enviada.Nos comunicaremos con usted tan pronto como esté completo, así que espere un momento."
  },
  { component: "project-list", label: "リクエストを送信しました", ja: "リクエストを送信しました", en: "Request sent", es: "Solicitud enviada" },
  { component: "project-list", label: "リクエストの送信に失敗しました", ja: "リクエストの送信に失敗しました", en: "Failed to send request", es: "No se pudo enviar la solicitud" },
  { component: "project-list", label: "プロジェクト名 ドメイン名 管理画面コード で検索", ja: "プロジェクト名 ドメイン名 管理画面コード で検索", en: "Search by Project name or Domain name or Admin code", es: "Buscar por nombre de proyecto, nombre de dominio o código de admin" },
  { component: "project-list", label: "プロジェクトの名前の編集", ja: "プロジェクトの名前の編集", en: "Edit project name", es: "Editar nombre del proyecto" },
  { component: "project-list", label: "新しいプロジェクトの名前", ja: "新しいプロジェクトの名前", en: "Name of new project", es: "Nombre del nuevo proyecto" },
  { component: "project-list", label: "修正", ja: "修正する", en: "Fix", es: "Arreglar" },
  { component: "project-list", label: "プロジェクト名を修正しました", ja: "プロジェクト名を修正しました", en: "Fixed project name", es: "Nombre del proyecto fijo" },
  { component: "project-list", label: "プロジェクトの登録を完了しました", ja: "プロジェクトの登録を完了しました", en: "Project creation is completed.", es: "Se completa la creación del proyecto." },
  { component: "stress-detail-chart", label: "【端末ID】のパフォーマンスの情報", ja: "【端末ID】のパフォーマンスの情報", en: "Performance information of 「Device ID」", es: "Información de desempeño de [ID dispositivo]" },
  { component: "stress-detail-chart", label: "時間帯", ja: "時間帯", en: "Time zone", es: "Zona horaria" },
  { component: "stress-detail-chart", label: "平均(PR)", ja: "平均(PR)", en: "Average (PR)", es: "Media (PR)" },
  { component: "stress-detail-chart", label: "疲労(TP)", ja: "疲労(TP)", en: "Fatigue  (TP)", es: "Fatiga (TP)" },
  { component: "stress-detail-chart", label: "平均(nemukedo)", ja: "平均(nemukedo)", en: "Average (Sleepiness)", es: "Media (sueño)" },
  { component: "stress-detail-chart", label: "平均(LFHF)", ja: "平均(LFHF)", en: "Average (LFHF)", es: "Media (LFHF)" },
  { component: "stress-detail-chart", label: "平均(1分間歩数)", ja: "平均(1分間歩数)", en: "Average (Steps per minute)", es: "Media (pasos/min)" },
  { component: "stress-detail-chart", label: "最大値(歩数)", ja: "最大値(歩数)", en: "Highest (Steps per minute)", es: "Máximo (pasos)" },
  { component: "stress-detail-chart", label: "平均(3軸加速度合成)", ja: "平均(3軸加速度合成)", en: "Average (3 axis acceleration)", es: "Media (Aceleraciones en 3 ejes)" },
  { component: "stress-detail-chart", label: "平均\r\n(3軸加速度合成)", ja: "平均\r\n(3軸加速度合成)", en: "Average \r\n(3 axis acceleration)", es: "Media \r\n(Aceleraciones en 3 ejes)" },
  { component: "stress-detail-chart", label: "歩数（累計）", ja: "歩数（累計）", en: "Steps (cumulative)", es: "Pasos (acumulativos)" },
  { component: "stress-detail-chart", label: "手動作業量", ja: "手動作業量", en: "Manual workload", es: "Carga de trabajo manual" },
  { component: "stress-detail-chart", label: "bpm", ja: "bpm", en: "bpm", es: "bpm" },
  { component: "stress-detail-chart", label: "TP", ja: "TP", en: "TP", es: "TP" },
  { component: "stress-detail-chart", label: "", ja: "", en: "", es: "" },
  { component: "stress-detail-chart", label: "LFHF", ja: "LFHF", en: "LFHF", es: "LFHF" },
  { component: "stress-detail-chart", label: "歩", ja: "歩", en: "steps", es: "pasos" },
  { component: "summary-detail", label: "計測時刻：Thu Apr 04 2024 16:53:59 GMT+0900 (日本標準時)", ja: "計測時刻：Thu Apr 04 2024 16:53:59 GMT+0900 (日本標準時)", en: "Measuring time: Thu Apr 04 2024 16:53:59 GMT+0900 (Japan Standard Time)", es: "Hora de medición: Jue. 24 de abril, 2024. 16:53:59 GMT+0900 (zona horaria de japón)" },
  { component: "summary-detail", label: "日付", ja: "日付", en: "Date", es: "Fecha" },
  { component: "summary-detail", label: "開始時刻", ja: "開始時刻", en: "Start time", es: "Hora de comienzo" },
  { component: "summary-detail", label: "終了時刻", ja: "終了時刻", en: "End time", es: "Hora de termino" },
  { component: "summary-detail", label: "状態", ja: "状態", en: "Condition", es: "Estado" },
  { component: "summary-detail", label: "アラートなし", ja: "アラートなし", en: "No Alerts", es: "Sin alarmas" },
  { component: "summary-detail", label: "更に表示する", ja: "更に表示する", en: "Show more", es: "Mostrar más" },
  { component: "summary-detail", label: "熱中症リスク", ja: "熱中症リスク", en: "Heat Stroke Risk", es: "Riesgo de golpe de calor" },
  { component: "summary-detail", label: "心拍モニタリング", ja: "心拍モニタリング", en: "Heart Rate Monitoring", es: "Monitoreo de frecuencia cardíaca" },
  { component: "summary-detail", label: "眠気モニタリング", ja: "眠気モニタリング", en: "Sleepiness monitoring", es: "Monitoreo de nivel de sueño" },
  { component: "summary-detail", label: "疲労モニタリング", ja: "疲労モニタリング", en: "Fatigue Monitoring", es: "Monitoreo de fatiga" },
  { component: "summary-detail", label: "気象モニタリング", ja: "気象モニタリング", en: "Weather Monitoring", es: "Monitoreo del clima" },
  { component: "summary-detail", label: "自律神経モニタリング", ja: "自律神経モニタリング", en: "Autonomic nervous system Monitoring", es: "Monitoreo sistema nervioso autónomo" },
  { component: "summary-detail", label: "運転モニタリング", ja: "運転モニタリング", en: "Driving monitoring", es: "Monitoreo de manejo" },
  { component: "summary-detail", label: "感情モニタリング", ja: "感情モニタリング", en: "Emotion Monitoring", es: "Monitoreo emocional" },
  { component: "summary-detail", label: "45432", ja: "45432", en: "05/20/2024", es: "45432" },
  { component: "summary-detail", label: "YYYY 年 MM 月 DD 日", ja: "YYYY 年 MM 月 DD 日", en: "YYYY/MM/DD", es: "YYYY/MM/DD" },
  { component: "summary-detail", label: "データなし", ja: "データなし", en: "No data", es: "Sin datos" },
  { component: "summary-detail", label: "注意", ja: "注意", en: "Caution", es: "Precaución" },
  { component: "summary-detail", label: "危険", ja: "危険", en: "Danger", es: "Peligro" },
  { component: "summary-detail", label: "平均の平均", ja: "平均の平均", en: "Average", es: "Media" },
  { component: "summary-detail", label: "標準偏差の平均", ja: "標準偏差の平均", en: "Standard deviation average", es: "Media de la desviación estandar" },
  { component: "summary-detail", label: "心拍数", ja: "心拍数", en: "Heart rate", es: "Frecuencia cardíaca" },
  { component: "summary-detail", label: "安静時心拍数", ja: "安静時心拍数", en: "Resting Heart Rate", es: "Frecuencia cardíaca en reposo" },
  { component: "summary-detail", label: "平均安静時心拍数", ja: "平均安静時心拍数", en: "Resting heart rate average", es: "Media de frecuencia cardíaca en reposo" },
  { component: "summary-detail", label: "です", ja: "です", en: "", es: "" },
  { component: "summary-detail", label: "判定なし", ja: "判定なし", en: "No data", es: "Sin evaluar" },
  { component: "summary-detail", label: "運動は原則禁止", ja: "運動は原則禁止", en: "Stop any Physical Exercise", es: "Pare toda actividad física" },
  { component: "summary-detail", label: "厳重警戒", ja: "厳重警戒", en: "Extreme caution", es: "Precaución extrema" },
  { component: "summary-detail", label: "激しい運動は中止", ja: "激しい運動は中止", en: "Stop Strenuous Exercise", es: "Evite actividad física extenuante" },
  { component: "summary-detail", label: "警戒", ja: "警戒", en: "Warning", es: "Alerta" },
  { component: "summary-detail", label: "積極的に休憩", ja: "積極的に休憩", en: "Please rest", es: "Descansar con urgencia" },
  { component: "summary-detail", label: "積極的に水分補給", ja: "積極的に水分補給", en: "Please hydrate urgently", es: "Hidratarse con urgencia" },
  { component: "summary-detail", label: "ほぼ安全", ja: "ほぼ安全", en: "Safe", es: "Estable" },
  { component: "summary-detail", label: "適宜水分補給", ja: "適宜水分補給", en: "Hydrate appropriately", es: "Hidratarse apropiadamente" },
  { component: "summary-detail", label: "高心拍", ja: "高心拍", en: "High heart rate", es: "Alta frecuencia cardíaca" },
  { component: "summary-detail", label: "分以上高い心拍数が続いています", ja: "分以上高い心拍数が続いています", en: "Minutes of continuous high heart rate", es: "Minutos de alta frecuencia cardíaca" },
  { component: "summary-detail", label: "低心拍", ja: "低心拍", en: "Low heart rate", es: "Baja frecuencia cardíaca" },
  { component: "summary-detail", label: "分以上低い心拍数が続いています", ja: "分以上低い心拍数が続いています", en: "Minutes of continuous low heart rate", es: "Minutos de baja frecuencia cardíaca" },
  { component: "summary-detail", label: "不整脈", ja: "不整脈", en: "Arrhythmia", es: "Arritmia" },
  { component: "summary-detail", label: "不整脈の発生回数が多くなっています", ja: "不整脈の発生回数が多くなっています", en: "High instances of arrhythmia", es: "Numerosos casos de arritmia" },
  { component: "user-list", label: "業務時間", ja: "業務時間", en: "Business hours", es: "Horas de trabajo" },
  { component: "user-list", label: "業務時間を編集", ja: "業務時間を編集", en: "Edit business hours", es: "Editar horario comercial" },
  { component: "user-list", label: "業務開始時刻", ja: "業務開始時刻", en: "Shift start time", es: "Comienzo de horario laboral" },
  { component: "user-list", label: "業務終了時刻", ja: "業務終了時刻", en: "Shift end time", es: "Termino de horario laboral" },
  { component: "user-list", label: "ユーザー一覧", ja: "ユーザー一覧", en: "User list", es: "Lista de usuarios" },
  { component: "user-list", label: "新規登録", ja: "新規登録", en: "New", es: "Nuevo" },
  // { component: "user-list", label: "新規登録", ja: "新規登録", en: "Register", es: "Añadir" },
  { component: "user-list", label: "名前", ja: "名前", en: "Name", es: "Nombre" },
  { component: "user-list", label: "所属", ja: "所属", en: "Afiliation", es: "Afiliación" },
  { component: "user-list", label: "電話番号", ja: "電話番号", en: "Phone number", es: "Número telefónico" },
  { component: "user-list", label: "管理者", ja: "管理者", en: "Administrator", es: "Supervisor" },
  { component: "user-list", label: "編集", ja: "編集", en: "Edit", es: "Editar" },
  { component: "user-list", label: "削除", ja: "削除", en: "Delete", es: "Eliminar" },
  { component: "user-list", label: "優先表示", ja: "優先表示", en: "Priority display", es: "Mostrar con prioridad" },
  { component: "user-list", label: "更新", ja: "更新", en: "Update", es: "Actualizar" },
  { component: "user-list", label: "ユーザ情報を登録", ja: "ユーザ情報を登録", en: "Register user information", es: "Registrar datos de usuario" },
  { component: "user-list", label: "名前", ja: "名前", en: "Name", es: "Nombre" },
  { component: "user-list", label: "電話番号", ja: "電話番号", en: "Phone number", es: "Número telefónico" },
  { component: "user-list", label: "所属", ja: "所属", en: "Afiliation", es: "Afiliación" },
  { component: "user-list", label: "管理者1", ja: "管理者1", en: "Administrator 1", es: "Supervisor 1" },
  { component: "user-list", label: "名前の項目が未入力です", ja: "名前の項目が未入力です", en: "No name has been entered", es: "Nombre sin ingresar" },
  { component: "user-list", label: "電話番号の項目が未入力です", ja: "電話番号の項目が未入力です", en: "No phone number has been entered", es: "Número telefónico sin ingresar" },
  { component: "user-list", label: "登録を完了しました", ja: "登録を完了しました", en: "Registration completed", es: "Registro finalizado" },
  { component: "user-list", label: "更新を完了しました", ja: "更新を完了しました", en: "Update completed", es: "Actualización terminada" },
  { component: "default-header", label: "言語設定", ja: "言語設定", en: "Language settings", es: "Ajustes de idioma" },
  { component: "lang/logic", label: "あなたの言語を選択してください", ja: "あなたの言語を選択してください", en: "Select your launguage", es: "Por favor seleccione su idioma" },
];

export let _LangDic = null;

export const getLangDictionary = () => {
  if (_LangDic) { return _LangDic; }

  _LangDic = {};
  _LangDictionary.forEach((item) => {
    _LangDic[item.label] = item;
  });

  return _LangDic;
}
